<template>
  <div style="height: 100vh;">
    <div class="header-title" style="padding:.2rem">
      <van-row gutter="10">
        <van-col span="24" class="" @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px;margin-top:.1rem"
          />
          <span style="font-size: 25px; color: white;font-weight:bold;margin-left:.1rem">消息</span>
        </van-col>
        <!-- <van-col span="14" style="text-align: right" @click="onIssueClick">
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col> -->
      </van-row>
      <van-row class="tabs">
        <van-col
          span="12"
          v-for="(item, index) in tabs"
          :key="index"
          :class="[item.type == type ? 'tab-item-active' : 'tab-item']"
          @click="onTabClick(item)"
          >{{ item.text }}</van-col
        >
      </van-row>
    </div>
    <div style="padding:.4rem .4rem 4rem .4rem;height:85vh; overflow-y:scroll;" v-if="type==='1,2,5'">
      <van-pull-refresh v-model="isLoading" @refresh="refreshNotifications">
        <van-list v-model="listLoading" :finished="notificationFinished" finished-text="没有更多了" 
          @load="loadNotificationPage">
          <card 
            v-for="(item, idx) in notificaions" :key="idx" 
            class="message-item" :style="{'background': item.relation.is_read? '#f1f1f1': '#fff'}"
            @click.native="toNotificationInfo(item.relation.id)">
            <template #content>
              <van-row style="font-size: .2rem">
                <van-col span="14" class="message-title-col">
                  <span :class="item.relation.is_read? 'tab-item-title-isread':
                    'tab-item-title'">
                    {{item.notification.title}}
                  </span>
                </van-col>
                <van-col span="10" style="font-size:12px;color:grey">
                  <span>{{item.notification.created_at}}</span>
                </van-col>
              </van-row>
              <div v-if="!item.relation.is_read" class="dot"/>
            </template>
          </card>
        </van-list>
      </van-pull-refresh>
    </div>
    <div style="padding:.4rem .4rem 4rem .4rem;height:85vh; overflow-y:scroll;" v-if="type===2">
      <van-pull-refresh v-model="isLoading" @refresh="refreshFeedbacks">
        <van-list v-model="listLoading" :finished="feedbackFinished" finished-text="没有更多了" @load="loadFeedbackPage">
          <card style="padding:.3rem;line-height:.3rem" v-for="(feedback, idx) in feedbacks" :key="idx" @click.native="onDetailClick(feedback.id)">
            <template #content>
              <van-row style="margin-top:-.2rem">
                <van-col span="24">
                  <span class="feedback_title_name">{{feedback.feedback_content == ''?'暂无评价':feedback.feedback_content}}</span>
                </van-col>
                <van-col span="24">
                  <van-tag v-for="(tag, idx) in feedback.tags" :key="idx" round type="primary"
                    style="margin-right:.1rem">
                    {{tag}}
                  </van-tag>
                </van-col>
                <van-col span="24" >
                  <van-rate v-model="feedback.rate_star" color="#ffd21e"
                  void-icon="star" disabled-color="#eee"
                  readonly :size="25"
                  allow-half/>
                </van-col>
              </van-row>
              <van-row style="font-size:.1rem;color:#666666;margin-top:-.2rem">
                <van-col span="12" class="feedback_comment_tag">
                  {{feedback.feedback_object.object}}
                </van-col>
                <van-col span="12" style="text-align:right">
                  <span class="feedback_comment_tag">
                    {{feedback.feedback_object.name}}
                  </span>
                </van-col>
              </van-row>
              <van-row style="font-size:.1rem;color:#666666;">
                <van-col span="12" class="feedback_comment_tag">回复时间</van-col>
                <van-col span="12" style="text-align:right" class="feedback_comment_tag">
                  {{feedback.created_at}}
                </van-col>
              </van-row>
            </template>
          </card>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import tourInfoTop from "@/views/components/TourInfoTop"
import guideInfo from "@/views/components/GuideInfo"
import {mapGetters} from 'vuex'
export default {
  components: {
    card,
    tourInfoTop,
    guideInfo
  },
  data:() => ({
    tabs: [
      {
        text: "通知",
        type: "1,2,5",
      },
      {
        text: "评价",
        type: 2,
      },
    ],
    type: "1,2,5",
    showNotification: false,
    showFeedbacks: false,
    isLoading: false,
    listLoading: false,
    notificationPaging: {
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      next_page: null
    },
    feedbackPaging: {
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      next_page: null
    }
  }),
  computed: {
    ...mapGetters('notification', { notificaions: 'getAllNotifications' }),
    ...mapGetters('feedback', ['feedbacks', 'feedback_tags']),
    ...mapGetters('wode', {
      user: 'getPersonInfo'
    }),
    notificationFinished() {
      return this.notificationPaging.next_page == null
    },
    feedbackFinished() {
      return this.feedbackPaging.next_page == null
    }
  },
  created() {
    const _self = this
    this.$store.dispatch('notification/getAllNotifications', {type: "1,2,5", page:1})
    this.$store.dispatch('feedback/get_feedbacks').then((res) => {
      if(res.status === 200) {
        _self.feedbackPaging.total_count = res.total_count
        _self.feedbackPaging.total_pages = res.total_pages
        _self.feedbackPaging.current_page = res.current_page
        _self.feedbackPaging.next_page = res.next_page
      }
    })
    this.$store.dispatch('feedback/get_feedback_tags')
    this.$store.dispatch('wode/getPersonInfo')
    const type = this.$route.query.type 
    if(type == 2) {
      this.showFeedbacks = true
      this.type = 2
    } else {
      this.showNotification = true
      this.type = '1,2,5'
    }
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
    toNotificationInfo(id) {
      this.$router.push(`/notification/info/${id}`)
    },
    onTabClick(item) {
      this.type = item.type;
      if (item.type == 2) {
        this.showNotification = false
        this.showFeedbacks = true
      } else {
        this.showNotification = true
        this.showFeedbacks = false
      }
    },
    onDetailClick(id) {
      this.$router.push({
        path: `/notification/feedback/${id}`, 
      })
    },
    refreshNotifications() {
      const _self = this
      this.listLoading = false
      this.$store.dispatch('notification/getAllNotifications', {type:"1,2,5", page:1}).then((res) => {
        if(res) {
          _self.isLoading = false
          _self.notificationPaging.total_count = res.total_count
          _self.notificationPaging.current_page = res.current_page
          _self.notificationPaging.next_page = res.next_page
          _self.notificationPaging.total_pages = res.total_pages
        }
      })
    },
    refreshFeedbacks() {
      const _self = this
      this.listLoading = false
      this.$store.dispatch('feedback/get_feedbacks').then((res) => {
        if(res.status === 200) {
          _self.isLoading = false
          _self.feedbackPaging.total_count = res.total_count
          _self.feedbackPaging.current_page = res.current_page
          _self.feedbackPaging.next_page = res.next_page
          _self.feedbackPaging.total_pages = res.total_pages
        }
      })
    },
    loadNotificationPage() {
      const _self = this
      if(this.notificationPaging.next_page != null) {
        this.$store.dispatch('notification/getAllNotifications', {type: "1,2,5", 
          page: this.notificationPaging.next_page}).then((res) => {
            if(res.status === 200) {
              _self.listLoading = false
              _self.notificationPaging.total_count = res.total_count
              _self.notificationPaging.current_page = res.current_page
              _self.notificationPaging.next_page = res.next_page
              _self.notificationPaging.total_pages = res.total_pages
              _self.$store.dispatch('notification/syncNotifications', res.data)
            }
          })
      }
    },
    loadFeedbackPage() {
      const _self = this
      if(this.feedbackPaging.next_page != null) {
        this.$store.dispatch('feedback/get_feedbacks',
          this.feedbackPaging.next_page).then((res) => {
            if(res.status === 200) {
              _self.listLoading = false
              _self.feedbackPaging.total_count = res.total_count
              _self.feedbackPaging.current_page = res.current_page
              _self.feedbackPaging.next_page = res.next_page
              _self.feedbackPaging.total_pages = res.total_pages
              _self.$store.dispatch('feedback/sync_feedbacks', res.data)
            }
          })
      }
    },
  }
};
</script>

<style>
.message-container {
  padding: 0 .4rem 2rem .4rem;
  margin-top: .2rem;
  height:80vh;
  overflow-y: scroll;
}
.message-item {
  min-height: 1rem;
  width: 100%;
  border-radius: 15px;
  line-height: .6rem;
  background-color: #fff;
  box-sizing: border-box;
  padding: .3rem .2rem .2rem .3rem;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  padding-top: 10px;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 0.3rem;
}
.message-title-col {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: .1rem;
}
.message-date {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 10px;
  color: #666;
}
.message-content {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipse;
  height: 40px;
  font-size: 12px;
  color: grey;
  margin-top: 10px;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
  background: #6c94f6;
  border-bottom-left-radius: 1rem
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.feedback_title_name {
  font-size: 0.3rem;
}
.feedback_comment_tag {
  font-size: .2rem;
  color: #666666;
}
.tab-item-title {
  font-weight: bold;
  font-size: 12px;
}
.tab-item-title-isread {
  font-weight: 600;
  font-size: 12px;
  color: #808080;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
</style>